import * as actions from './types';

/**
 * Repository action generators.
 * @module actions/repository
 * @category Actions
 */

/**
 * Action for setting documents.
 * @function
 * @param {Array} documents -
 * @param {number} resultsCount -
 */
export const setDocuments = (documents = [], resultsCount = 0) => ({
  type: actions.repository.SET_DOCUMENTS,
  payload: {
    documents,
    resultsCount,
  },
});

/**
 * Action for setting repository options.
 * @function
 * @param {object} options - additional options
 */
export const setRepositoryOptions = (options = {}) => ({
  type: actions.repository.SET_REPOSITORY_OPTIONS,
  payload: {
    options: options || {},
  },
});

/**
 * Action for updating filter.
 * @function
 * @param {} filter -
 * @param {} value -
 * @param {} options - contains subtree, orderBy and a filterConfig
 */
export const updateFilter = (filter, value, options) => ({
  type: actions.repository.UPDATE_FILTER,
  payload: {
    filter,
    value,
    options,
  },
});

/**
 * Action for updating filter of subtree.
 * @function
 * @param {boolean} subtree -
 */
export const updateFilterSubtree = (subtree = true) => ({
  type: actions.repository.UPDATE_FILTER,
  payload: {
    options: {
      subtree,
    },
  },
});

export const updateFilterSubstructure = (substructure = true) => ({
  type: actions.repository.UPDATE_FILTER,
  payload: {
    options: {
      substructure,
    },
  },
});

/**
 * Action for setting filter.
 * @function
 * @param {} filter -
 * @param {} subtree -
 * @param {} filterConfig -
 */
export const setFilter = (filter, subtree, filterConfig) => ({
  type: actions.repository.SET_FILTER,
  payload: {
    filter,
    subtree,
    filterConfig,
  },
});

/**
 * Action to clear filter.
 * @function
 * @param {boolean} clearSearchQuery -
 * @param {boolean} subtree -
 */
export const clearFilter = (clearSearchQuery = false, subtree = false) => ({
  type: actions.repository.CLEAR_FILTER,
  payload: {
    clearSearchQuery,
    subtree,
  },
});

/**
 * Action for updating search query.
 * @function
 * @param {} searchQuery -
 */
export const updateSearchQuery = (searchQuery) => ({
  type: actions.repository.UPDATE_SEARCH_QUERY,
  payload: {
    searchQuery,
  },
});

/**
 * Action to clear repository data.
 * @function
 */
export const clearRepositoryData = () => ({
  type: actions.repository.CLEAR_REPOSITORY_DATA,
});

/**
 * Action to clear repository query.
 * @function
 */
export const clearRepositoryQuery = () => ({
  type: actions.repository.CLEAR_REPOSITORY_QUERY,
});

/**
 * Action for setting folder.
 * @function
 * @param {} folder -
 */
export const setFolder = (folder) => ({
  type: actions.repository.SET_FOLDER,
  folder,
});

/**
 * Action for setting folder id.
 * @function
 * @param {} projectCode -
 * @param {} folderId -
 */
export const setFolderId = (projectCode, folderId) => ({
  type: actions.repository.SET_FOLDER_ID,
  payload: {
    projectCode,
    folderId,
  },
});

/**
 * Action for setting page count paginator.
 * @function
 * @param {} pageCount -
 */
export const setPaginatorPageCount = (pageCount) => ({
  type: actions.repository.SET_PAGINATOR_PAGE_COUNT,
  pageCount,
});

/**
 * Action for setting paginator page.
 * @function
 * @param {} pageIndex -
 */
export const setPaginatorPage = (pageIndex) => ({
  type: actions.repository.SET_PAGINATOR_PAGE,
  payload: {
    pageIndex,
  },
});

/**
 * Action for setting order by parameter.
 * @function
 * @param {} orderBy -
 */
export const setOrderBy = (orderBy) => ({
  type: actions.repository.SET_ORDERBY,
  payload: {
    orderBy,
  },
});

/**
 * Action for resetting order.
 * @function
 */
export const resetOrderBy = () => ({
  type: actions.repository.RESET_ORDERBY,
});

/**
 * Action for fetching type folders.
 * @function
 * @param {} folderType - type of the folder
 * @param {object} options - additional options
 * @param {function} options.callback - action results callback
 */
export const fetchTypeFolders = (folderType, options = {}) => ({
  type: actions.repository.FETCH_FOLDERS,
  payload: {
    folderType,
    options: {
      callback: options?.callback || (() => {}),
    },
  },
});

/**
 * Action for setting repository loading status.
 * @function
 * @param {boolean} loading - status flag of repository loading
 * @param {string} message - toast message
 */
export const setRepositoryLoading = (loading, message) => ({
  type: actions.repository.SET_REPOSITORY_LOADING,
  loading,
  message,
});

/**
 * Action to request documents.
 * @function
 * @param {object} options - additional options
 * @param {boolean} options.includeQuery - whether folder is used as a resource in the workflow
 * @param {boolean} options.disablePagination - action results callback
 */
export const requestDocuments = (options = {}) => ({
  type: actions.repository.REQUEST_DOCUMENTS,
  payload: {
    options: {
      includeQuery: options?.includeQuery || true,
      disablePagination: options?.disablePagination || false,
    },
  },
});

export const refreshRepositorySearch = (force = true) => ({
  type: actions.repository.REFRESH_REPOSITORY_SEARCH,
  payload: {
    force,
  },
});

/**
 * Action to fetch aggregate latest.
 * @function
 * @param {string} propertyName -
 * @param {Map.<string, any>} groupingPropertyName -
 * @param {object} resourceType -
 * @param {function} callback - action results callback
 */
export const fetchAggregateLatest = (
  propertyName,
  groupingPropertyName,
  resourceType,
  callback = () => {}
) => ({
  type: actions.repository.FETCH_AGGREGATE_LATEST,
  payload: {
    propertyName,
    groupingPropertyName,
    resourceType,
    options: {},
    callback,
  },
});

/**
 * Action to delete documents.
 * @function
 * @param {function} callback - action results callback
 */
export const deleteDocuments = () => ({
  type: actions.repository.DELETE_DOCUMENTS,
});

/**
 * Action to delete version.
 * @function
 * @param {} versionId - id of deleting version
 * @param {function} callback - action results callback
 */
export const deleteVersion = (versionId, callback = () => {}) => ({
  type: actions.repository.DELETE_VERSION,
  versionId,
  callback,
});

/**
 * Action to undelete documents.
 * @function
 * @param {function} callback - action results callback
 */
export const undeleteDocuments = (callback = () => {}) => ({
  type: actions.repository.UNDELETE_DOCUMENTS,
  callback,
});

/**
 * Action to select documents.
 * @function
 * @param {} documents -
 * @param {} followingActions -
 */
export const selectDocuments = (documents, ...followingActions) => ({
  type: actions.repository.SELECT_DOCUMENTS,
  documents,
  followingActions,
});

/**
 * Action to select documents identifiers.
 * @function
 * @param {} identifiers -
 * @param {} followingActions -
 */
export const selectDocumentsIdentifiers = (
  identifiers,
  ...followingActions
) => ({
  type: actions.repository.SELECT_DOCUMENTS_IDENTIFIERS,
  identifiers,
  followingActions,
});

/**
 * Action for setting selected documents.
 * @function
 * @param {} documents -
 */
export const setSelectedDocuments = (documents) => ({
  type: actions.repository.SET_SELECTED_DOCUMENTS,
  documents,
});

/**
 * Action for setting errors validation.
 * @function
 * @param {} validationErrors -
 */
export const setValidationErrors = (validationErrors) => ({
  type: actions.repository.SET_VALIDATION_ERRORS,
  validationErrors,
});

/**
 * Action to toggle validation error.
 * @function
 * @param {} id -
 * @param {} propertyName -
 * @param {} active -
 */
export const toggleValidationError = (id, propertyName, active) => ({
  type: actions.repository.TOGGLE_VALIDATION_ERROR,
  id,
  propertyName,
  active,
});

/**
 * Action for updating selected documents.
 * @function
 * @param {string} documentId - id of selected document
 * @param {string} propertyName - name of the property
 * @param {} value -
 * @param {boolean} validation - status of validation
 */
export const updateSelectedDocument = (
  documentId,
  propertyName,
  value,
  validation = true
) => ({
  type: actions.repository.UPDATE_SELECTED_DOCUMENT,
  documentId,
  propertyName,
  value,
  validation,
});

/**
 * Action to require project selection.
 * @function
 */
export const requireProjectSelection = () => ({
  type: actions.repository.PROJECT_SELECTION_NEEDED,
});

/**
 * Action for setting proxy folder id.
 * @function
 * @param {string} folderId - if of the folder
 * @param {} sector -
 */
export const setProxyFolderId = (
  folderId,
  contextOnly = false,
  force = false
) => ({
  type: actions.repository.SET_PROXY_FOLDER_ID,
  folderId,
  contextOnly,
  force,
});

/**
 * Action to create sub folder.
 * @function
 * @param {boolean} folderType -
 * @param {function} callback - action results callback
 */
export const createSubfolder = (folderType) => ({
  type: actions.repository.CREATE_SUBFOLDER,
  folderType,
});

/**
 * Action to create simple sub folder.
 * @function
 * @param {boolean} folderName - name of the folder
 * @param {function} callback - action results callback
 */
export const createSimpleSubfolder = (folderName, callback = () => {}) => ({
  type: actions.repository.CREATE_SIMPLE_SUBFOLDER,
  folderName,
  callback,
});

/**
 * Action for deleting folder.
 * @function
 * @param {function} callback - action results callback
 */
export const deleteFolder = (callback) => ({
  type: actions.repository.DELETE_FOLDER,
  callback,
});

/**
 * Action to link documents.
 * @function
 * @param {Array} documents -
 */
export const linkDocuments = (documents) => ({
  type: actions.repository.LINK_DOCUMENTS,
  payload: {
    documents,
  },
});

/**
 * Action to unlink documents.
 * @function
 * @param {Array} documents -
 */
export const unlinkDocuments = (documents) => ({
  type: actions.repository.UNLINK_DOCUMENTS,
  payload: {
    documents,
  },
});

/**
 * Action for fetching linked documents.
 * @function
 * @param {function} callback - action results callback
 */
export const fetchLinkedDocuments = () => ({
  type: actions.repository.FETCH_LINKED_DOCUMENTS,
});

/**
 * Action for fetching children documents.
 * @function
 * @param {function} callback - action results callback
 */
export const fetchChildrenDocuments = (
  propertyName,
  queryPropertyName,
  documentId
) => ({
  type: actions.repository.FETCH_CHILDREN_DOCUMENTS,
  payload: {
    propertyName,
    queryPropertyName,
    documentId,
  },
});

export const fetchAndAttachChildrenDocuments = (
  propertyName,
  queryPropertyName
) => ({
  type: actions.repository.FETCH_CHILDREN_DOCUMENTS,
  payload: {
    propertyName,
    queryPropertyName,
    attachToParent: true,
  },
});

/**
 * Action to attach linked documents.
 * @function
 * @param {string} documentId - id of the document
 * @param {} linkedDocuments -
 */
export const attachLinkedDocuments = (documentId, linkedDocuments) => ({
  type: actions.repository.ATTACH_LINKED_DOCUMENTS,
  documentId,
  linkedDocuments,
});

/**
 * Action for setting repository readiness.
 * @function
 * @param {} ready -
 */
export const setRepositoryReady = (ready) => ({
  type: actions.repository.SET_REPOSITORY_READY,
  ready,
});

/**
 * Action for fetching search suggestions.
 * @function
 * @param {} searchQuery -
 * @param {function} callback - action results callback
 */
export const fetchSearchSuggestions = (searchQuery, callback = () => {}) => ({
  type: actions.repository.FETCH_SEARCH_SUGGESTIONS,
  searchQuery,
  callback,
});

/**
 * Action for exporting folder Excel.
 * @function
 */
export const exportFolderExcel = (options) => ({
  type: actions.repository.EXPORT_FOLDER_EXCEL,
  payload: {
    options,
  },
});

/**
 * Action for exporting documents Excel.
 * @function
 */
export const exportDocumentsExcel = (options) => ({
  type: actions.repository.EXPORT_DOCUMENTS_EXCEL,
  payload: {
    options,
  },
});

/**
 * Action for exporting documents Excel.
 * @function
 */
export const exportUrlsExcel = (allDocuments = false, callback = () => {}) => ({
  type: actions.repository.EXPORT_URLS_EXCEL,
  allDocuments,
  callback,
});

/**
 * Action for exporting zip documents.
 * @function
 * @param {function} callback - action results callback
 */
export const exportDocumentsZip = () => ({
  type: actions.repository.EXPORT_DOCUMENTS_ZIP,
});

/**
 * Action to preserve selected documents.
 * @function
 * @param {} documentIds -
 * @param {boolean} validation -
 * @param {function} callback - action results callback
 */
export const preserveSelectedDocuments = (
  documentIds = [],
  validation = true
) => ({
  type: actions.repository.PRESERVE_SELECTED_DOCUMENTS,
  documentIds,
  validation,
});

/**
 * Action to set bulk update progress.
 * @function
 * @param {} bulkUpdateProgress -
 */
export const setBulkUpdateProgress = (bulkUpdateProgress) => ({
  type: actions.repository.SET_BULK_UPDATE_PROGRESS,
  bulkUpdateProgress,
});

/**
 * Action for calculating presets.
 * @function
 * @param {} documentIds -
 */
export const calculatePresets = (documentIds = []) => ({
  type: actions.repository.CALCULATE_PRESET,
  documentIds,
});

/**
 * Action to update preset.
 * @function
 * @param {string} propertyName - property name
 * @param {string} value -
 * @param {Array} documentIds -
 * @param {Array} documents -
 * @param {boolean} updatePreset -
 */
export const updatePreset = (
  propertyName,
  value,
  documentIds = [],
  documents = [],
  options
) => ({
  type: actions.repository.UPDATE_PRESET,
  payload: {
    propertyName,
    value,
    documentIds,
    documents,
    options: {
      updatePreset: options?.updatePreset != null ? options.updatePreset : true,
      instantSave: !!options?.instantSave,
    },
  },
});

/**
 * Action to start business process.
 * @function
 * @param {} document -
 */
// export const setDocumentAsPreset = (document) => ({
//   type: actions.repository.SET_DOCUMENT_AS_PRESET,
//   document,
// });

/**
 * Action to fetch deleted documents.
 * @function
 * @param {function} callback - action results callback
 * @param {} paginator -
 * @param {string} orderBy - rules of ordering
 */
export const fetchDeletedDocuments = (paginator, orderBy) => ({
  type: actions.repository.FETCH_DELETED_DOCUMENTS,
  payload: {
    paginator,
    orderBy,
  },
});

/**
 * Action for importing files.
 * @function
 * @param {boolean} files -
 * @param {function} callback - action results callback
 */
export const importFiles = (files, callback = () => {}) => ({
  type: actions.repository.IMPORT_FILES,
  files,
  callback,
});

/**
 * Action to open folder.
 * @function
 * @param {} folderIdentifier -
 */
export const openFolder = (folderIdentifier, options = {}) => ({
  type: actions.repository.OPEN_FOLDER,
  payload: {
    folderIdentifier,
    contextOnly: !!options?.contextOnly,
    force: !!options?.force,
  },
});

/**
 * Action to close folder.
 * @function
 */
export const closeFolder = () => ({
  type: actions.repository.CLOSE_FOLDER,
});

/**
 * Action to set selected folder.
 * @function
 * @param {} folder -
 */
export const setSelectedFolder = (folder) => ({
  type: actions.repository.SET_SELECTED_FOLDER,
  folder,
});

/**
 * Action to update selected folder.
 * @function
 * @param {string} propertyName - property name
 * @param {} value -
 * @param {} multiValueIndex -
 */
export const updateSelectedFolder = (propertyName, value, multiValueIndex) => ({
  type: actions.repository.UPDATE_SELECTED_FOLDER,
  payload: {
    propertyName,
    value,
    multiValueIndex,
  },
});

/**
 * Action to update multi attribute of selected folder.
 * @function
 * @param {} propertyNames -
 * @param {} multiValueIndexes -
 */
export const updateSelectedFolderMultiAttribute = (
  propertyNames,
  multiValueIndexes
) => ({
  type: actions.repository.UPDATE_SELECTED_FOLDER_MULTI_ATTRIBUTE,
  payload: {
    propertyNames,
    multiValueIndexes,
  },
});

/**
 * Action to preserve selected folder.
 * @function
 * @param {function} callback - action results callback
 */
export const preserveSelectedFolder = () => ({
  type: actions.repository.PRESERVE_SELECTED_FOLDER,
});

/**
 * Action to initiate folder of preset.
 * @function
 * @param {string} folderType - type of the folder
 * @param {function} callback - action results callback
 */
export const initiateFolderOfPreset = (folderType) => ({
  type: actions.repository.INITIATE_FOLDER_OF_PRESET,
  payload: {
    folderType,
  },
});

/**
 * Action to cancel folder wizard.
 * @function
 * @param {function} callback - action results callback
 */
export const cancelFolderWizard = (callback = () => {}) => ({
  type: actions.repository.CANCEL_FOLDER_WIZARD,
  callback,
});

/**
 * Action to virtual move documents.
 * @function
 * @param {} filter -
 * @param {function} callback - action results callback
 */
export const virtualMoveDocuments = (filter, callback = () => {}) => ({
  type: actions.repository.VIRTUAL_MOVE_DOCUMENTS,
  filter,
  callback,
});

/**
 * Action to create document reference.
 * @function
 * @param {object} options - additional options
 * @param {function} options.callback - action results callback
 * @param {function} options.openTargetFolder -
 * @param {function} options.targetFolderId -
 */
export const createDocumentReference = (options = {}) => ({
  type: actions.repository.CREATE_DOCUMENT_REFERENCE,
  payload: {
    options: {
      openTargetFolder: !!options?.openTargetFolder,
      targetResourceIds: options?.targetResourceIds,
    },
  },
});

/**
 * Action to run project query.
 * @function
 * @param {boolean} queryId -
 * @param {function} callback - action results callback
 */
export const runProjectQuery = (queryId, callback = () => {}) => ({
  type: actions.repository.PROJECT_QUERY_RUN,
  queryId,
  callback,
});

export const setSearchProperties = (searchProperties) => ({
  type: actions.repository.SET_SEARCH_PROPERTIES,
  payload: {
    searchProperties,
  },
});

export const setSearchOptions = (searchOptions) => ({
  type: actions.repository.SET_SEARCH_OPTIONS,
  payload: {
    searchOptions,
  },
});

/**
 * Action for saving sub folder draft.
 * @function
 * @param {} folderType - type of the folder
 * @param {function} callback - action results callback
 */
export const saveSubfolderDraft = (folderType) => ({
  type: actions.repository.SAVE_SUBFOLDER_DRAFT,
  folderType,
});

/**
 * Action for copying to clipboard.
 * @function
 * @param {string} mode - rule of copying to clipboard
 */
export const copyToClipboard = (mode) => ({
  type: actions.repository.COPY_TO_CLIPBOARD,
  mode,
});

/**
 * Action for highlighting documents.
 * @function
 * @param {} documentIds -
 */
export const highlightDocs = (documentIds) => ({
  type: actions.repository.HIGHLIGHT_DOCS,
  documentIds,
});

/**
 * Action to paste clipboard.
 * @function
 * @param {string} id - id for the folder where the content should be pasted
 */
export const pasteClipboard = (folderId) => ({
  type: actions.repository.PASTE_CLIPBOARD,
  payload: { folderId },
});

/**
 * Action to clear clipboard.
 * @function
 */
export const clearClipboard = () => ({
  type: actions.repository.CLEAR_CLIPBOARD,
});

/**
 * Action for setting thumbnail.
 * @function
 * @param {} thumbnail -
 */
export const setThumbnail = (thumbnail) => ({
  type: actions.repository.SET_THUMBNAIL,
  thumbnail,
});

/**
 * Action to create folder duplicate.
 * @function
 * @param {} folderId -
 * @param {string} folderType - type of the folder
 * @param {object} options - additional options
 * @param {function} options.callback - action results callback
 */
export const createFolderDuplicate = (folderId, folderType, options = {}) => ({
  type: actions.repository.CREATE_FOLDER_DUPLICATE,
  payload: {
    folderId,
    folderType,
    options: {
      callback: options?.callback || (() => {}),
    },
  },
});

/**
 * Action to create document duplicate.
 * @function
 * @param {object} options - additional options
 * @param {function} options.callback - action results callback
 */
export const createDocumentDuplicate = (options = {}) => ({
  type: actions.repository.CREATE_DOCUMENT_DUPLICATE,
  payload: {
    options: {
      callback: options?.callback || (() => {}),
    },
  },
});

export const fetchDocumentsByIdentifiers = (identifiers) => ({
  type: actions.repository.FETCH_DOCUMENTS_BY_IDENTIFIERS,
  identifiers,
});

export const handleResourceUpdateEvent = (
  resourceId,
  data,
  resourceType = 2
) => ({
  type: actions.repository.HANDLE_RESOURCE_UPDATE_EVENT,
  payload: {
    resourceId,
    data,
    resourceType,
  },
});

export const setFolderTreeState = (folderIdentifier, initialTreeState) => ({
  type: actions.repository.SET_FOLDER_TREE_STATE,
  payload: {
    folderIdentifier,
    initialTreeState,
  },
});

export const updateFolderTreeStateOption = (treeLevelOptionIdentifier) => ({
  type: actions.repository.UPDATE_FOLDER_TREE_STATE_OPTION,
  payload: {
    treeLevelOptionIdentifier,
  },
});

export const updateFolderTreeStateOptions = (preset) => ({
  type: actions.repository.UPDATE_FOLDER_TREE_STATE_OPTIONS,
  payload: {
    preset,
  },
});

// export const setThumbnailLoading = (loading) => ({
//   type: actions.repository.SET_THUMBNAIL_LOADING,
//   loading,
// });

// /**
//  * Action is not used now.
//  * @function
//  * @param {function} callback - action results callback
//  */
// export const fetchSubfolderDocuments = (callback = () => {}) => ({
//   type: actions.repository.FETCH_SUBFOLDER_DOCUMENTS,
//   callback,
// });
//
// export const clearSelection = () => ({
//   type: actions.repository.CLEAR_SELECTION,
// });
//
// export const setFolders = (folders) => ({
//   type: actions.repository.SET_FOLDERS,
//   folders,
// });
//
export const clearSearchQuery = () => ({
  type: actions.repository.CLEAR_SEARCH_QUERY,
});
//
// export const setFilterOptions = (filterOptions) => ({
//   type: actions.repository.SET_FILTER_OPTIONS,
//   filterOptions,
// });

export const unlockDocuments = () => ({
  type: actions.repository.UNLOCK_DOCUMENTS,
});

/**
 * Action for adding folder items (eg. Companies for Company Secretary, Departments for Digital Archive...)
 * @function
 * @param {Array} folderItems -
 */
export const setFolderItems = (folderItems) => ({
  type: actions.repository.SET_FOLDER_ITEMS,
  payload: {
    folderItems,
    disablePagination: true,
  },
});

/**
 * Action for adding Label of active folder(eg. CompanyName for Company Secretary...)
 * @function
 * @param {string} label
 */
export const setFolderItemsLabel = (label) => ({
  type: actions.repository.SET_FOLDER_ITEMS_LABEL,
  payload: {
    label,
  },
});

/**
 * Action for adding folder items (eg. Companies for Company Secretary, Departments for Digital Archive...) * @function
 */
export const getFolderItems = () => ({
  type: actions.repository.GET_FOLDER_ITEMS_LABEL,
});

